module.exports = {

  slugify: function (text, separator) {
    text = `${text}`.toString().toLowerCase().trim();

    const sets = [
      { to: 'a', from: '[Ą]' },
      { to: 'c', from: '[Ć]' },
      { to: 'e', from: '[Ę]' },
      { to: 'l', from: '[Ł]' },
      { to: 'n', from: '[Ń]' },
      { to: 'o', from: '[Ó]' },
      { to: 's', from: '[Ś]' },
      { to: 'z', from: '[ŹŻ]' },
      { to: 'i', from: '[í]' },
      { to: '-', from: '[·/_,:;\']' }
    ];

    sets.forEach(set => {
      text = `${text}`.replace(new RegExp(set.from, 'gi'), set.to);
    });

    text = `${text}`.toString().toLowerCase()
      .replace(/\s+/g, '-')         // Replace spaces with -
      .replace(/&/g, '-and-')       // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
      .replace(/\--+/g, '-')        // Replace multiple - with single -
      .replace(/^-+/, '')           // Trim - from start of text
      .replace(/-+$/, '');          // Trim - from end of text

    if ((typeof separator !== 'undefined') && (separator !== '-')) {
      text = `${text}`.replace(/-/g, separator);
    }

    return text;
  },

  getNumberFromString: function (string) {
    return string.match(/(\d+)/) && string.match(/(\d+)/)[0];
  },

  findByKey: function (arr, key) {
    return arr.find((el) => el.key === key);
  },

  filterByKey: function (arr, key) {
    return arr.filter((el) => el.key === key)
  },

  getLanguageByLocation: function (location) {
    return location.pathname.slice(1,3)
  },
}
