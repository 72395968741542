import React from "react";
import { HelmetDatoCms } from "gatsby-source-datocms";

export const SEOMetaTags = (data) => {

  const prevLink = data && data.prevLink;
  const nextLink = data && data.nextLink;
  const canonicalLink = data && data.canonicalLink;
  const pageName = data && data.pageName;
  const pageNameData = data && data.propertyReference && data.propertyReference.find(el => el.key === "pageName");
  const pageNameDato = pageNameData && pageNameData.value;
  const { alternateLinks } = data;

  return (
    <HelmetDatoCms seo={{ tags: data.tags || [] }}>
      <html lang={data.lang} />
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
      <meta httpEquiv="Cache-Control" content="max-age=3600"/>
      <meta name="google-site-verification" content="oec4S5aP6w5wBadFzh9zMieUTWiMRPp9LYhiyfm4dgE"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="facebook-domain-verification" content="vpsa1ju6vm35nzyw2mtl61maofb6gr" />

      {prevLink}
      {nextLink}
      {canonicalLink}
      <title>{pageName || pageNameDato || "PushPushGo"}</title>
      <script async defer src="//www.instagram.com/embed.js"/>
      <script src="//code.tidio.co/vow0ygllkktaxuhbj2gmihtdrpzyp0ag.js"></script>
      <script charSet="UTF-8" src="https://cdn.pushpushgo.com/js/57a04ba53f29062f1fed6d8f.js" async="async"/>
      {alternateLinks && alternateLinks.map(({ locale, link }) => <link key={Math.random() + Date.now()} rel="alternate" hrefLang={locale} href={link}/>)}
    </HelmetDatoCms>
  )
}
