import React from "react"
import "./TitleSection.scss"
import classNames from "classnames"
import { PricingSwitch } from "../PricingSwitch/PricingSwitch"

import { findByKey } from "../../helpers/helpers"

export const TitleSection = ({ propertyReference }) => {
  const greenVersion = propertyReference.find(
    (el) => el.value === "green-version"
  )
  const greyVersion = propertyReference.find(
    (el) => el.value === "grey-version"
  )

  const text = findByKey(propertyReference, "title").value
  const alignLeft = findByKey(propertyReference, "align")
  const priceList = findByKey(propertyReference, "price-list-version")
  const tableVersion = findByKey(propertyReference, "compatibility-table")
  const regularSpaces = findByKey(propertyReference, "spaces")
  const blogVersion = findByKey(propertyReference, "blog")
  const description = findByKey(propertyReference, "paragraph")
  const switchSection = findByKey(propertyReference, "switch")

  const classValue = classNames({
    "title-section": true,
    "price-list": priceList && priceList.value,
    "grey-version": greyVersion && greyVersion.value,
    "green-version": greenVersion && greenVersion.value,
    "table-version": tableVersion && tableVersion.value,
    "left-align": alignLeft && alignLeft.value,
    "regular-spaces": regularSpaces && regularSpaces.value,
    "blog-version": blogVersion && blogVersion.value,
  })

  return (
    <section className={classValue}>
      <div className="content-limiter">
        <h1>{text}</h1>
        {description && <p>{description.value}</p>}
        {switchSection && switchSection.value === "show" ? (
          <div className={"switch-wrapper"}>
            <PricingSwitch
              labels={[switchSection.value2, switchSection.value3]}
              links={[switchSection.value4, switchSection.value5]}
            />
          </div>
        ) : null}
      </div>
    </section>
  )
}
