import * as React from "react";
import "./GreyFooter.scss";
import { IGreyFooter } from "../../helpers/interfaces";

interface IGreyFooterProps {
  footer: IGreyFooter[];
  lang: string;
}

export const GreyFooter = ({ footer, lang }: IGreyFooterProps): JSX.Element => {

  const footerCategories = footer;
  const currentYear = new Date().getFullYear();
  const otherOrder = lang === "es" ? "version-ordered" : "";

  return (
    <footer className="main-footer">
      <div className="content-limiter grey-footer">

        <div className="footer11">
          <a href="https://pushpushgo.com" className="logo">PushPushGo</a>
          <p className="copyright">Copyright © {currentYear} by PushPushGo.<br/> All rights reserved.</p>
        </div>
        {footerCategories.map(category => {
          return <div className={`footer-navigation ${category.footerId} ${otherOrder}`}
                      key={Math.random() + new Date().toDateString()}>
            <h4>{category.title}</h4>
            <nav>
              {category.footerSubcategory.map(subCategory => {
                return <a href={`${subCategory.link}`} key={Math.random() + new Date().toDateString()}>
                  {subCategory.icon && <span className={`icon ${subCategory.icon}`}/>}
                  {subCategory.title}
                </a>;
              })}
            </nav>
          </div>;
        })}
        <div className="footer-navigation footer15">
          <div className="socials">
            <a href="https://www.linkedin.com/company/10915197" rel="noreferrer" target="_blank">
              <span className="icon-linkedin"/>
            </a>
            <a href="https://twitter.com/PushPushGo" rel="noreferrer" target="_blank">
              <span className="icon-twitter"/>
            </a>
            <a href="https://www.facebook.com/PushPushGo/" rel="noreferrer" target="_blank">
              <span className="icon-facebook"/>
            </a>
          </div>
          <div className="review-image">
            <a
              href="https://www.capterra.com/reviews/173993/PushPushGo?utm_source=vendor&amp;utm_medium=badge&amp;utm_campaign=capterra_reviews_badge">
              <img src="https://assets.capterra.com/badge/36fa885f8385fa417b0b27ed52bafb56.png?v=2120693&amp;p=173993
  https://assets.capterra.com/badge/36fa885f8385fa417b0b27ed52bafb56.png?v=2120693&amp;p=173993" alt="Capterra"/>
            </a>
          </div>
        </div>

      </div>
    </footer>
  );
};
