import * as React from "react";
import "./CallToAction.scss";
import { StaticImage } from "gatsby-plugin-image";
import { ICallToAction } from "../../helpers/interfaces";

interface ICallToActionProps {
  cta: ICallToAction;
}

export const CallToAction = ({ cta }: ICallToActionProps): JSX.Element => {

  const ctaData = cta[0];

  return (
    <section className="call-to-action">
      <div className="content">
        <h3>{ctaData && ctaData.title}</h3>
        <p>{ctaData && ctaData.description}</p>
        <a href={ctaData && ctaData.url} className="btn register">{ctaData && ctaData.btnContent}</a>
        <p className="light">
          <span className={`${ctaData && ctaData.icon} text-green`}/>
          {ctaData && ctaData.subtitle}
        </p>
      </div>
      <div className="bg-img">
        <StaticImage src={"../../assets/images/app-screen2.png"} layout={"fullWidth"} alt={""}/>
      </div>
    </section>
  );
};
