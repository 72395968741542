import React, { useState, useEffect, useRef } from "react";
import "./MainMenu.scss";
import ClickToCall from "./../ClickToCall/ClickToCall";
import useModal from "../useModal";
import { StaticImage } from "gatsby-plugin-image";

const MainMenu = (props) => {

  const node = useRef();

  const lang = props && props.lang;
  const [ bigScreen, setScreenWidth ] = useState(true);
  const [ language, setLanguage ] = useState(lang || 'pl');
  const [ showMenu, setMenuVisible ] = useState(false);
  const [ scrolled, setScrolled ] = useState();
  const { isShowing, toggle } = useModal();

  const menuCategory1 = props.menu[ 0 ];
  const menuCategory2 = props.menu[ 1 ];
  const menuCategory3 = props.menu[ 2 ];
  const menuCategory4 = props.menu[ 3 ];
  const menuCategory5 = props.menu[ 4 ];
  const menuCategory6 = props.menu[ 5 ];

  const imgSrc = props && ( lang === 'pl' ?
    'https://www.datocms-assets.com/6307/1541781960-rodoready.png' :
    'https://www.datocms-assets.com/6307/1541781962-gdprready-logo.png' );

  const controlDeviceWidth = () => {
    setScreenWidth(window.innerWidth > 1230);
  };

  const closeClick = e => {
    !node.current.contains(e.target) && setMenuVisible(false);
  };

  const bodyScrolled = () => {
    window.pageYOffset > 75 ? setScrolled('scrolled') : setScrolled(undefined);
  };

  const handleOnKeyDown = e => {
    if (e.keyCode === 13) {
      setMenuVisible(!showMenu);
    }
  };

  useEffect(() => {
    controlDeviceWidth();
    window.addEventListener("resize", controlDeviceWidth);
    window.addEventListener("scroll", bodyScrolled);
    document.addEventListener("mousedown", closeClick);

    return () => {
      window.removeEventListener("resize", controlDeviceWidth);
      window.removeEventListener("scroll", bodyScrolled);
      document.removeEventListener("mousedown", closeClick);
    };
  }, []);

  return (
    <header className={`main-menu-section ${scrolled}`} ref={node}>
      <div className="content-limiter main-menu-container">
        <div className="logo">
          <a href={`/${lang}/`}>
            <StaticImage src={"../../assets/images/logo.png"} layout={"fixed"} width={170} height={45}
                         alt="PushPushGo"/>
          </a>
          <img className="rodo" src={imgSrc} alt=""/>
        </div>
        {bigScreen ? (
          <div className="nav-items">
            <nav className="main-nav">
                  <span className="nav-item toggle-menu">
                    <span className="toggle-menu-wrapper">
                      {menuCategory1.title} <span className="icon-arrow-down toggle-menu-item"/>
                      </span>
                      <ul className="dropdown dropdown-submenu">
                        {menuCategory1.menuSubcategory.map(subCategory => {
                          return <li key={subCategory.slug}>
                            <a href={subCategory.link} className="nav-item">
                              <span className={`icon ${subCategory.icon}`}/>
                              {subCategory.title}
                            </a>
                          </li>;
                        })}
                      </ul>
                    </span>
              {<a href={menuCategory2.link} className="nav-item">{menuCategory2.title}</a>}
              <span className="nav-item toggle-menu-statistics">
                    <span className="toggle-menu-wrapper">
                    {menuCategory3.title} <span className="icon-arrow-down toggle-menu-item"/>
                      </span>
                      <ul className="dropdown dropdown-submenu-statistics">
                        {menuCategory3.menuSubcategory.map(subCategory => {
                          return <li key={subCategory.slug}>
                            <a href={subCategory.link} className="nav-item">
                              <span className={`icon ${subCategory.icon}`}/>
                              {subCategory.title}
                            </a>
                          </li>;
                        })}
                      </ul>
                    </span>
            </nav>
            <nav className="auth-nav">
                    <span className="call-us">
                      <span className="icon-telephone"/>
                      <span className="phone-number">{menuCategory4.link}</span>
                    </span>
              <a href="/#" className="nav-item" onClick={toggle}>{menuCategory4.title}</a>
              <a href={menuCategory5.link} className="nav-item">{menuCategory5.title}</a>
              <a href={menuCategory6.link} className=" sign-up-btn btn register">{menuCategory6.title}</a>
              <div className="language-toggle">
                <div className="language-toggle-list">
                  <a href={`/pl/`} className={`${language === "pl" ? `active` : ''}`}
                     onClick={() => setLanguage("pl")}>pl</a>
                  <a href={`/en/`} className={`${language === "en" ? `active` : ''}`}
                     onClick={() => setLanguage("en")}>en</a>
                </div>
              </div>
            </nav>
          </div>
        ) : (
          <>
            <span className="icon-menu main-menu-toggle"
                  role={'button'}
                  tabIndex={0}
                  aria-label="Open the menu"
                  onClick={() => setMenuVisible(!showMenu)}
                  onKeyDown={handleOnKeyDown}/>
            {showMenu && (
              <div className="nav-items">
                <nav className="main-nav">
              <span className="nav-item toggle-menu">
                <span className="toggle-menu-wrapper">
                {menuCategory1.title} <span className="icon-arrow-down toggle-menu-item"/>
                  </span>
                  <ul className="dropdown dropdown-submenu">
                    {menuCategory1.menuSubcategory.map(subCategory => {
                      return <li key={subCategory.slug}>
                        <a href={subCategory.link} className="nav-item">
                          <span className={`icon ${subCategory.icon}`}/>
                          {subCategory.title}
                        </a>
                      </li>;
                    })}
                  </ul>
                </span>
                  <a href={menuCategory2.link} className="nav-item">{menuCategory2.title}</a>
                  <span className="nav-item toggle-menu-statistics">
                    <span className="toggle-menu-wrapper">
                    {menuCategory3.title} <span className="icon-arrow-down toggle-menu-item"/>
                    </span>
                    <ul className="dropdown dropdown-submenu-statistics">
                      {menuCategory3.menuSubcategory.map(subCategory => {
                        return <li key={subCategory.slug}>
                          <a href={subCategory.link} className="nav-item">
                            <span className={`icon ${subCategory.icon}`}/>
                            {subCategory.title}
                          </a>
                        </li>;
                      })}
                    </ul>
                  </span>
                </nav>
                <nav className="auth-nav">
                <span className="call-us">
                  <span className="icon-telephone"/>
                  <span className="phone-number">{menuCategory4.link}</span>
                </span>
                  <a href="/#" className="nav-item" onClick={toggle}>{menuCategory4.title}</a>
                  <a href={menuCategory5.link} className="nav-item">{menuCategory5.title}</a>
                  <a href={menuCategory6.link}
                     className="btn--medium sign-up-btn btn register">{menuCategory6.title}</a>
                  <div className="language-toggle">
                    <div className="language-toggle-list">
                      <a href={`/pl/`} className={`${language === "pl" ? `active` : ''}`}
                         onClick={() => setLanguage("pl")}>pl</a>
                      <a href={`/en/`} className={`${language === "en" ? `active` : ''}`}
                         onClick={() => setLanguage("en")}>en</a>
                    </div>
                  </div>
                </nav>
              </div> )}
          </>
        )}
      </div>
      <ClickToCall c2c={props.c2c} lang={lang} isShowing={isShowing} hide={toggle}/>
    </header>
  );
};

export default React.memo(MainMenu, (prevProps, nextProps) => {
  return ( ( prevProps.bigScreen === nextProps.bigScreen ) ||
    ( prevProps.showMenu === nextProps.showMenu ) );
});
