import * as React from "react";
import "./FundingBanner.scss";
import { GatsbyImage } from "gatsby-plugin-image";

export const FundingBanner = (props): JSX.Element => {

  const banner = props && props.banner[0];
  return (
    <div className="funding-banner">
      <GatsbyImage image={banner && banner.image.gatsbyImageData} alt={banner.image.alt || ""}/>
    </div>
  );
};
