import React from "react";
import "./BlogBreadcrumbs.scss";

import { findByKey } from "../../helpers/helpers"

export const BlogBreadcrumbs = ({ pages }) => {

  const page = findByKey(pages, "page");
  const title = findByKey(pages, "title");

  return (
    <div className="blog-breadcrumbs">
      <a href="https://pushpushgo.com/">PushPushGo</a>
      {page && <><span className="divider">/</span><a href={page.url}>{page.value}</a></>}
      {title && <><span className="divider">/</span><a href={title.url}>{title.value}</a></>}
    </div>
  )
};
