import React, { useState } from "react";
import "./ClickToCall.scss";
import Select from "../Select/Select";
import {regexpNumber} from "../../helpers/regexp";

const ClickToCall = (props) => {

  const c2cContent = props.c2c[ 0 ];
  const lang = props.lang;

  const [ type, setType ] = useState("");
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const { isShowing, hide } = props;
  const [ callType, setCallType ] = useState("INFO");

  const options = [
    { value: "INFO", label: c2cContent.selectOption1 },
    { value: "TECHNICAL", label: c2cContent.selectOption2 },
    { value: "PARTNERS_PROGRAM", label: c2cContent.selectOption3 },
    { value: "FAX", label: c2cContent.selectOption4 },
  ];

  const callPPG = (event) => {
    event.preventDefault();
    if (validateNumber()) {
      fetch("https://api.pushpushgo.com/csas/phone-call", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          callType: callType,
          language: lang,
        }),
      })
      .then(res => {
        res.ok ? setType("ok") : setType("error");
        setPhoneNumber("");
      })
      .catch(error => {
        setType("error");
        console.log("Error: ", error);
      });
    } else {
      setType("validate");
    }
  };

  const validateNumber = () => {
    return phoneNumber.match(regexpNumber);
  };

  function feedback(type) {
    switch (type) {
      case "ok":
        return <span id="c2c-status-ok" className="c2c-status">{c2cContent.statusOk}</span>;
      case "error":
        return <span id="c2c-status-error" className="c2c-status">{c2cContent.statusError}</span>;
      case "validate":
        return <span id="c2c-status-validate" className="c2c-status">{c2cContent.statusValidate}</span>;
      case "loading":
        return <span id="c2c-status-loading" className="c2c-status">{c2cContent.statusLoading}</span>;
      default:
        return null;
    }
  }

  return (
    <>
      {isShowing ? (
        <div className="c2c-modal">
          <div className="c2c-modal-backdrop"/>
          <div className="c2c-modal-box" id="c2c-modal">
            <span className={`${c2cContent.icon} icon`}/>
            <h1 className="c2c-modal-title">{c2cContent.title}</h1>
            <p>{c2cContent.selectTitle}</p>
            <Select options={options} onChange={event => setCallType(event.target.value)}/>
            <p className="c2c-feedback-descr">{c2cContent.subtitle}</p>
            <div className="c2c-modal-input-wrapper">
              <input className="c2c-modal-input" type="text" placeholder="+48" value={phoneNumber}
                     onChange={event => setPhoneNumber(event.target.value)}/>
            </div>
            {feedback(type)}
            <div className="c2c-modal-btn-wrapper">
              <a href="/#" className="c2c-modal-btn" onClick={callPPG}>{c2cContent.btn}</a>
            </div>
            <a href="/#" className="c2c-modal-close" onClick={hide}>
              <span aria-label="Close popup"/>
            </a>
            <p>
              <small>
                {c2cContent.description}
              </small>
            </p>

          </div>
        </div>
      ) : null}
    </>
  );
};

export default ClickToCall;
