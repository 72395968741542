import React from "react"
import "./Select.scss"

const Select = ({ options, onChange }) => {
  return <div className="custom-select">
    <select onChange={onChange}>
      {options.map((option, idx) => {
        return <option key={idx} value={option.value}>{option.label}</option>
      })}
    </select>
    <span className="custom-arrow icon-arrow-down " />
  </div>
}

export default Select
