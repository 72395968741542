import * as React from 'react';
import './PricingSwitch.scss'
import { FC, useEffect, useState } from "react"

interface ISwitchProps {
  labels: string[]
  links: string[]
}

export const PricingSwitch:FC<ISwitchProps> = ({labels, links}) => {
  const [ isLite, setLite ] = useState(false);

  useEffect(() => {
    window.location.pathname.includes('lite') ? setLite(true) : setLite(false);
  }, [])

  const redirect = (path):void => {
    window.location.href = path;
  }

  const onClickHandler = ():void => {
    setLite(!isLite)
    window.location.pathname.includes('lite') ? redirect(links[0]) : redirect(links[1]);
  };

  return <>
    <div className="btn-container">
      <label className="switch btn-color-mode-switch" >
        <input type="checkbox" name="color_mode" id="color_mode" checked={isLite} onChange={() => onClickHandler()}/>
          <label htmlFor="color_mode" data-on={labels[1]} data-off={labels[0]} className={`btn-color-mode-switch-inner`} />
      </label>
    </div>
  </>
}
